import React from 'react';
import {Form, InputField, Button, Row, Column} from "../forms/Form";
import {FetchJSON} from "../../functions/api_tools";
import autobind from "../../functions/autobind";

export default class ResetPasswordRequestForm extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            sent: false,
            user_data: {email: ''},
            user_data_errors: {}
        };
    }

    componentDidMount() {
    }

    handleEmailChange(value){
        this.setState(prev =>{
            prev.user_data.email = value;
            return prev;
        })
    }

    handleSubmit(e){
        e.preventDefault();

        FetchJSON('/password', {user: this.state.user_data}).then(response => response.json()).then(data =>{
            if (data.sent){
                this.setState({sent: true,user_data_errors: {}})
            }else{
                this.setState(prev => {
                    prev.user_data_errors = data.errors
                    return prev;
                })
            }
        });
    }


    render() {
        return this.state.sent ?
            (
                <Form className="login-section-container login-form">
                    <p>Instructions Sent! Check your inbox</p>
                </Form>
            )
            :
            (
                <Form className="login-section-container login-form" onSubmit={this.handleSubmit}>
                    <p>Enter your email and we will send you instructions on how to reset your password.</p>
                    <Row><Column>
                        <InputField key="email" type="email" label="Email" value={this.state.user_data.email} error={this.state.user_data_errors.email} onChange={this.handleEmailChange}/>
                    </Column></Row>

                    <div className="actions">
                        <Button className="button submit" value="Reset Password"/>
                    </div>
                    <div className="login-link login-link-block" style={{margin_top:'10px'}}>
                        <a onClick={this.props.loginClicked}>Log In</a>
                    </div>
                    <div className="login-link login-link-block" style={{margin_top:'10px'}}>
                        <a onClick={this.props.registerClicked}>Register For Free</a>
                    </div>
                </Form>
            )

    }
}
