import React from 'react';
import {Form, InputField, Button, Row, Column} from "../forms/Form";
import {FetchJSON} from "../../functions/api_tools";
import autobind from "../../functions/autobind";

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            user_data: {email: '', password: ''},
            error_message: ''
        };
    }

    setUserData(key,value){
        this.setState(prev =>{
            prev.user_data[key] = value;
            return prev;
        })
    }

    handleEmailChange = this.setUserData.bind(this,'email');
    handlePasswordChange = this.setUserData.bind(this,'password');

    handleSubmit(e){
        e.preventDefault();

        let url = this.props.loginContext === 'sso_link' ? '/sso/login_to_link_create' : '/login'


        FetchJSON(url, {user: this.state.user_data}).then(response => response.json()).then(data =>{
            if (data.success){
                this.props.handleLogin(data.location);
            }else{
                this.setState({error_message: data.error});
            }
        })
    }

    render() {

        return (
            <Form className="login-section-container login-form" onSubmit={this.handleSubmit}>
                {this.state.error_message && <Row><Column><div className='login-error'>{this.state.error_message}</div></Column></Row>}
                <Row><Column>
                    <InputField key="email" type="email" label="Email" value={this.state.user_data.email} onChange={this.handleEmailChange}/>
                </Column></Row>
                <Row><Column>
                    <InputField type="password" key="password" label="Password" value={this.state.user_data.password} onChange={this.handlePasswordChange}/>
                </Column></Row>

                <Row className="checkbox">
                    <Column>
                        <InputField key="remember" type="checkbox" label="Remember Me" labelPosition="after" />
                    </Column>

                    <div className="form-column forgot-password">
                        <a onClick={this.props.forgotPasswordClicked}>Forgot Password?</a>
                    </div>
                </Row>

                <div className="actions">
                    <Button className="button submit" value="Log In"/>
                </div>

                {this.props.loginContext === 'sso_link' ?
                    <></>
                    :
                    <div className="login-link login-link-block" style={{margin_top:'10px'}}>
                    <a onClick={this.props.registerClicked}>Register For Free</a>
                    </div>

                }

            </Form>
        );
    }
}
